<template>
  <div class="checkBoxDiv">
    <span
      class="item"
      :style="item.checked && {'backgroundColor': store.state.themeColor}"
      :class="{itemActive: item.checked}"
      v-for="item in list"
      :key="item.value"
      @click="handleClick(item)"
    >
      {{item.label}}
    </span>
  </div>
</template>

<script>
import { watch, computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'CommonRadio',
  props: {
    isCheckAll: {
      type: Boolean,
      default: false
    },
    allValue: {
      type: Number || String,
      default: 0
    },
    value: {
      type: Number,
      default: () => {
        return 0
      }
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const allItem = reactive({
      label: '全部',
      value: props.allValue,
      checked: false
    })
    const list = ref([])
    watch(() => props, () => {
      list.value = props.isCheckAll ? [allItem, ...props.options] : props.options
      list.value.map(i => {
        i.checked = props.value === i.value
        return i
      })
    }, { deep: true, immediate: true })
    const handleClick = item => {
      if (props.readOnly) {
        return
      }
      if (!item.checked) {
        list.value.map(i => {
          i.checked = i.value === item.value
        })
      } else {
        list.value.map(i => {
          i.checked = i.value === allItem.value
        })
      }

      const res = list.value.filter(i => i.checked).map(i => {
        return i.value
      })
      emit('update:value', res[0])
      emit('handleClick')
    }
    return {
      store,
      allItem,
      list,
      handleClick
    }
  }
}
</script>

<style lang="less" scoped>
.checkBoxDiv{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  display: inline-block;
  .item{
    cursor: pointer;
    margin: 0 6px;
    padding: 2px 6px;
    width: 48px;
    height: 22px;
    border-radius: 4px;
    white-space: nowrap;
  }
  .itemActive{
    color: white;
  }
}
</style>
