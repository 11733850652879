<template>
  <div class="card">
    <div
      class="cover"
      @click="$emit('openSetChapter')"
    >
      <img :src="src" width="140" height="187" alt="" />
    </div>
    <div class="title">
      {{ title }}
    </div>
    <div class="dvBtn">
      <a-button
            class="hoverBtn"
            type="primary"
            size="small"
            @click.stop="$emit('openEdit')"
            >编辑基础信息</a-button
          >
          <a-button
            class="hoverBtn"
            type="primary"
            size="small"
            @click.stop="$emit('openSetChapter')"
            >设置章节内容</a-button
          >

          <a-popconfirm
            :title="current.status=== 0 ? '确定上架吗？' : '确定下架吗？'"
            ok-text="是"
            cancel-text="否"
            @confirm="handleUpdateStatus"
            @visibleChange="handleVisibleChange"
          >
            <a-button
              class="hoverBtn"
              size="small"
              @click.stop="handleUpdateStatusVisible">
              {{current.status=== 0 ? '上架' : '下架'}}
            </a-button>
          </a-popconfirm>

          <a-popconfirm
            title="确定删除吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete"
            @visibleChange="handleVisibleChange"
          >
            <a-button
              v-permission="PermissionMap.TEACHING_MATERIAL_DELETE"
              class="hoverBtn"
              size="small"
              @click.stop="handleDeleteVisible"
              >删除</a-button
            >
          </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { ref, toRef, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { PermissionMap } from '@/utils/auth'

export default {
  name: 'CoverBlock',
  props: {
    title: String,
    src: String,
    data: Object,
    type: String,
    copy: Function,
    del: Function,
    updateStatus: Function,
    edit: Function
  },
  setup (props, { emit }) {
    const store = useStore()
    const isHover = ref(false)
    const current = toRef(props, 'data')
    const coverLock = ref(false)

    const handleDeleteVisible = (e) => {
      coverLock.value = true
    }
    const handleUpdateStatusVisible = (e) => {
      coverLock.value = true
    }
    const handleDelete = () => {
      emit('del')
      coverLock.value = false
    }
    const handleUpdateStatus = () => {
      emit('updateStatus')
      coverLock.value = false
    }
    const handleVisibleChange = (visible) => {
      if (!visible) {
        coverLock.value = false
        isHover.value = false
      }
    }
    onBeforeMount(async () => {
      console.log(121212, current.value.id, props)
    })
    return {
      store,
      current,
      isHover,
      PermissionMap,
      handleDelete,
      handleVisibleChange,
      handleDeleteVisible,
      handleUpdateStatus,
      handleUpdateStatusVisible
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin: auto;
  // height: 230px;
  // width: 140px;
  padding-top: 12px;
  .cover {
    text-align: center;
    position: relative;
    .hoverDiv {
      bottom: 0;
      width: 100%;
      position: absolute;
      height: 131px;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.62)
      );
      opacity: 0.62;
      text-align: center;
    }
    .hoverBtnDiv {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 20px;
      .hoverBtn {
        margin: 90px 3px 0 3px;
      }
    }
  }
  .title {
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: -1px;
    color: #3d4457;
    // display: -webkit-box;
    // -webkit-box-orient: vertical;
    // -webkit-line-clamp: 2;
  }
  .dvBtn{
    margin-top: 8px;
    display: flex;
    justify-content: space-evenly;
  }
}
</style>
