export const moduleCodeMap = {
  1: { name: '标准教学', id: 1 },
  2: { name: '特色课堂', id: 2 },
  3: { name: '曲库', id: 3 }
}

export const moduleCodeEnum = {
  standardCourse: 1,
  featureCourse: 2,
  songLibrary: 3
}
