<template>
<a-modal v-model:visible="modalVisible" :footer="null" :afterClose="handleClose" width='65%'>
  <div class="itxst">
    <div style="width:43%">
      <draggable
        :list="sectionsList"
        ghost-class="ghost"
        chosen-class="chosenClass"
        animation="300"
        @start="onStart"
        @end="onEnd"
      >
        <template #item="{ element }">
          <div :id="`section-`+element.id" :class="{'item':true, 'Highlight': isHighlightId===element.id}" @click.stop="handleSection(element)">
            <div>{{ element.name }}</div>
            <div class="item-btn">
              <div @click.stop="openEdit(element)"><FormOutlined /></div>
              <div >
                <a-popconfirm
            title="确定删除该章节吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="openDel(element)"
          >
            <DeleteOutlined />
          </a-popconfirm>

              </div>
            </div>
          </div>

        </template>
      </draggable>
      <div class="add-btn">
          <button  @click="add">新增章节</button>
        </div>
    </div>
    <div style="width:52% ">
      <div class="dvTop">
      <div>免费章节</div>
      <div class="ml24">
        <a-switch v-model:checked="isFree" checked-children="是" un-checked-children="否" @change="handleIsFree"/>
      </div>
    </div>
    <draggable
        :list="attachmentList"
        ghost-class="ghost"
        chosen-class="chosenClass"
        animation="300"
        @start="onStartAttachment"
        @end="onEndAttachment"
      >
        <template #item="{ element }">
          <div class="attachment-item">
            <div>
              <img v-if="element.type==='video'" :src="element.poster" class="coverImgAttachment" alt="avatar" />
              <img v-else :src="element.url" class="coverImgAttachment" alt="avatar" />
            </div>
            <div class="attachment-item-btn">
              <!-- <div @click.stop="openEditAttachment(element)"><FormOutlined  style="font-size: 2em;"/></div> -->
              <div >
                <a-popconfirm
            title="确定删除该资源吗?"
            ok-text="是"
            cancel-text="否"
            @confirm="openDelAttachment(element)"
          >
            <DeleteOutlined style="font-size: 2em;"/>
          </a-popconfirm>

              </div>
            </div>
          </div>

        </template>
      </draggable>
      <!-- {{ attachmentList }} -->
      <div class="add-btn">
        <button  @click="handleAddAttachment">添加新内容</button>
      </div>
      </div>
  </div>
</a-modal>
<a-modal v-model:visible="editVisible" :title="editModalTitle" @ok="handleOk">
      <div>章节名称</div>
      <div ><a-input v-model:value="formState.name" /></div>
</a-modal>
<a-modal v-model:visible="modalAttachmentVisible" :footer="null" :afterClose="handleAttachmentClose" :width="620">
    <div class="content">
      <a-form ref="formAttachmentRef" :rules="rules" :model="attachment"  labelAlign="right">

        <a-form-item name="type" label="资源类型" required>
          <CommonRadio v-model:value="attachment.type" :options="attachmentType" />
        </a-form-item>
        <a-form-item v-if="attachment.type==='video'" name="poster" label="封面" >
          <a-upload
            v-model:file-list="filePosterList"
            name="poster"
            list-type="picture-card"
            :show-upload-list="false"
            :before-upload="beforePosterUpload"
          >
            <img v-if="attachment.poster" :src="attachment.poster" class="coverImg" alt="avatar" />
            <div v-else-if="filePosterList.length < 1">
              <plus-outlined ></plus-outlined>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item name="url" label="资源"  >
          <a-upload
            v-model:file-list="fileUrlList"
            name="url"
            list-type="picture"
            class="upload-list-inline"
            :before-upload="beforeUpload"
          >
          <a-button v-if="fileUrlList.length < 1">
            <upload-outlined></upload-outlined>
            上传
          </a-button>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
    <div class="footer">
      <a-button type="primary" size="large" class="submitBtn" @click="handleAttachmentSubmit">
        确定
      </a-button>
    </div>
  </a-modal>
  <div class="example" v-if="uploadLoading">
    <a-spin tip="资源上传中..."/>
  </div>
</template>
<script>
import { reactive, ref, computed, onBeforeUnmount, toRef, watch, onBeforeMount } from 'vue'
import draggable from 'vuedraggable'
import { message } from 'ant-design-vue'
import CommonRadio from '@/components/common/CommonRadio'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import {
  getCourseSections,
  setCourseSections
} from '@/services/course-resource-management'
import OSS from 'ali-oss'
import moment from 'moment'
import { getOssUploadConfig } from '@/services/oss'

export default {
  name: 'SetChapter',
  props: {
    type: String,
    visible: Boolean,
    data: Object
  },
  components: {
    CommonRadio,
    draggable,
    DeleteOutlined,
    FormOutlined
  },
  setup (props, { emit }) {
    const fileUrlList = ref([])
    const filePosterList = ref([])
    const attachment = ref({
      type: 'video',
      url: '',
      poster: ''
    })
    const attachmentType = ref([
      { value: 'video', label: '视频' },
      { value: 'image', label: '图片' }
    ])
    const formState = ref({
      id: '',
      is_free: '',
      name: '',
      poster: '',
      sort: 1
    })
    const ossConfig = {
      domain: null,
      client: null
    }
    const current = computed(() => props.data)
    const editVisible = ref(false)
    const modalAttachmentVisible = ref(false)
    const formAttachmentRef = ref()
    const formRef = ref()
    const isHighlightId = ref(0)
    const isFree = ref(false)
    const isFirst = ref(true)
    const attachmentList = ref([])
    const editModalTitle = ref('新增章节')
    const loading = ref(false)
    const modalVisible = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val)
      }
    })
    const uploadLoading = ref(false)

    const handleAttachmentSubmit = async () => {
      formAttachmentRef.value.validate().then(async () => {
        if (attachment.value.url === '') {
          message.error('资源不能为空')
          return
        }
        if (attachment.value.type === 'video') {
          if (attachment.value.poster === '') {
            message.error('封面不能为空')
            return
          }
        }
        sectionsList.value.forEach((item, index) => {
          if (item.id === isHighlightId.value) {
            if (sectionsList.value[index].attachments === null) {
              sectionsList.value[index].attachments =
             [{
               type: attachment.value.type,
               url: attachment.value.url,
               poster: attachment.value.poster
             }]
              attachmentList.value = [{
                type: attachment.value.type,
                url: attachment.value.url,
                poster: attachment.value.poster
              }]
            } else {
              sectionsList.value[index].attachments.push({
                type: attachment.value.type,
                url: attachment.value.url,
                poster: attachment.value.poster
              })
              attachmentList.value = sectionsList.value[index].attachments
            }
          }
        })
        const params = {
          sections: sectionsList.value
        }
        setCourseSections(props.data.id, params)
        handleAttachmentClose()
      }).catch(e => {
        console.log(e)
      })
    }
    const uploadPath = (path, file) =>
      `${path}/${moment().format('YYYYMMDD')}/${file.name.split('.')[0]}-${
        file.uid
      }.${file.type.split('/')[1]}`

    const uploadToOss = (path, file) => {
      const storeAs = uploadPath(ossConfig.basePath, file)
      return new Promise((resolve, reject) => {
        ossConfig.client
          .multipartUpload(storeAs, file)
          .then(data => {
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
    const beforePosterUpload = file => {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      if (!isJPG) {
        message.error('请上传 jpeg、png、jpg 格式图片!')
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        // 使用ossupload覆盖默认的上传方法
        uploadToOss('/', file).then(data => {
          const url = new URL(data.res.requestUrls)
          file.url = ossConfig.domain + url.pathname
          filePosterList.value = [file]
          attachment.value.poster = file.url
        })
      }
      return false // 不调用默认的上传方法
    }
    const beforeUpload = async(file) => {
      uploadLoading.value = true
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        // 使用ossupload覆盖默认的上传方法
        uploadToOss('/', file).then(data => {
          const url = new URL(data.res.requestUrls)
          file.url = ossConfig.domain + url.pathname
          fileUrlList.value = [file]
          attachment.value.url = file.url
          uploadLoading.value = false
        })
      }
      return false // 不调用默认的上传方法
    }
    // 选中章节是否免费
    const handleIsFree = () => {
      let is_free = 0
      if (isFree.value) {
        is_free = 1
      }
      sectionsList.value.forEach((info, index) => {
        if (info.id === isHighlightId.value) {
          sectionsList.value[index].is_free = is_free
        }
      })
      const params = {
        sections: sectionsList.value
      }
      setCourseSections(props.data.id, params)
    }
    const handleAddAttachment = (data) => {
      if (sectionsList.value.length > 0) {
        modalAttachmentVisible.value = true
      } else {
        message.info('请先添加章节')
      }
    }
    const handleSection = (data) => {
      isHighlightId.value = data.id
      if (data.is_free === 0) {
        isFree.value = false
      } else {
        isFree.value = true
      }
      if (data.attachments === null) {
        attachmentList.value = []
      } else {
        attachmentList.value = data.attachments
      }
    }
    // 关闭弹窗初始化数据
    const handleAttachmentClose = () => {
      modalAttachmentVisible.value = false
      attachment.value.type = 'video'
      attachment.value.url = ''
      attachment.value.poster = ''
      fileUrlList.value = []
      filePosterList.value = []
    }
    const handleClose = () => {
      editVisible.value = false
      isHighlightId.value = 0
      isFree.value = false
      isFirst.value = true
      attachmentList.value = []
    }
    const id = ref(1)
    const sectionsList = ref([])
    // 拖拽开始的事件
    const onStart = () => {
      console.log('开始拖拽')
    }

    // 拖拽结束的事件
    const onEnd = () => {
      sectionsList.value.forEach((info, index) => {
        sectionsList.value[index].sort = index + 1
      })
      const params = {
        sections: sectionsList.value
      }
      setCourseSections(props.data.id, params)
      // await getData()
    }
    // 拖拽开始的事件
    const onStartAttachment = () => {
      console.log('开始拖拽')
    }

    // 拖拽结束的事件
    const onEndAttachment = () => {
      sectionsList.value.forEach((info, index) => {
        if (info.id === isHighlightId.value) {
          sectionsList.value[index].attachments = attachmentList.value
        }
      })
      const params = {
        sections: sectionsList.value
      }
      setCourseSections(props.data.id, params)
    }
    const openEditAttachment = (data) => {
      console.log('edit', data, attachmentList.value)
    }
    const openDelAttachment = (data) => {
      const newArray = attachmentList.value.filter(item => item.url !== data.url)
      attachmentList.value = newArray
      onEndAttachment()
    }
    const openEdit = (data) => {
      isHighlightId.value = data.id
      editModalTitle.value = '修改章节'
      formState.value.name = data.name
      formState.value.id = data.id
      editVisible.value = true
    }
    // 保存章节名称
    const handleOk = async(e) => {
      if (formState.value.name === '') {
        message.error('章节名称不能为空')
        return
      }
      if (sectionsList.value.length === 0) {
        // formState.value.attachments = []
        sectionsList.value.push(formState.value)
      } else {
        if (formState.value.id > 0) {
          sectionsList.value.forEach((info, index) => {
            if (info.id === formState.value.id) {
              sectionsList.value[index].name = formState.value.name
            }
          })
        } else {
          sectionsList.value.push({ name: formState.value.name })
        }
      }
      const params = {
        sections: sectionsList.value
      }
      await setCourseSections(props.data.id, params)
      await getData()
      if (formState.value.id > 0) {
        // isHighlightId.value = sectionsList.value[sectionsList.value.length - 1].id
      } else {
        isHighlightId.value = sectionsList.value[sectionsList.value.length - 1].id
        if (sectionsList.value[sectionsList.value.length - 1].attachments === null) {
          attachmentList.value = []
        } else {
          attachmentList.value = sectionsList.value[sectionsList.value.length - 1].attachments
        }
      }

      editVisible.value = false
    }
    // 删除章节
    const openDel = async(data) => {
      const newArray = sectionsList.value.filter(item => item.id !== data.id)
      sectionsList.value = newArray
      const params = {
        sections: sectionsList.value
      }
      await setCourseSections(props.data.id, params)
      isFirst.value = true
      await getData()
    }
    const replace = () => {
      sectionsList.value = [{ name: 'Edgard', id: id.value++ }]
    }
    const add = () => {
      editModalTitle.value = '新增章节'
      formState.value = {
        id: '',
        is_free: '',
        name: '',
        poster: '',
        sort: 1
      }
      editVisible.value = true
    }

    const getData = async () => {
      const { sections } = await getCourseSections(current.value.id)
      sections.sort((a, b) => a.sort - b.sort)

      sectionsList.value = sections
      if (isFirst.value) {
        isHighlightId.value = sectionsList.value[0].id
        if (sectionsList.value[0].is_free === 0) {
          isFree.value = false
        } else {
          isFree.value = true
        }
        if (sectionsList.value[0].attachments === null) {
          attachmentList.value = []
        } else {
          attachmentList.value = sectionsList.value[0].attachments
        }
      }

      // dataSource.value = items
      // paginator.current = pagination.current
      // paginator.total = pagination.total
      // data.tableLoading = false
    }

    watch(() => props.visible, async (newVal) => {
      if (newVal) {
        await getData()
      }
    })
    watch(() => attachment.value.type, async (newVal) => {
      attachment.value.url = ''
      attachment.value.poster = ''
      fileUrlList.value = []
      filePosterList.value = []
    })
    const getOssConfig = async () => {
      const { upload_config: data } = await getOssUploadConfig()
      ossConfig.client = new OSS({
        accessKeyId: data.access_key_id,
        accessKeySecret: data.access_key_secret,
        region: data.region,
        bucket: data.bucket,
        stsToken: data.sts_token
      })
      ossConfig.domain = data.download_domain
      ossConfig.basePath = data.base_path
    }
    onBeforeMount(async () => {
      await getOssConfig()
    })

    return {
      openDelAttachment,
      openEditAttachment,
      onStartAttachment,
      onEndAttachment,
      uploadLoading,
      handleAttachmentClose,
      handleAttachmentSubmit,
      beforePosterUpload,
      loading,
      formAttachmentRef,
      beforeUpload,
      filePosterList,
      fileUrlList,
      sectionsList,
      handleClose,
      formRef,
      modalVisible,
      onStart,
      onEnd,
      handleSection,
      replace,
      add,
      openEdit,
      openDel,
      editVisible,
      handleOk,
      formState,
      attachmentList,
      editModalTitle,
      isHighlightId,
      isFree,
      handleIsFree,
      modalAttachmentVisible,
      handleAddAttachment,
      attachment,
      attachmentType
    }
  }
}
</script>

<style lang="less" scoped>
.example {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  text-align: center;
  background: rgba(0, 0, 0, 0.55);

}

.coverImg{
  max-height: 200px;
  width: 200px;
}
.coverImgAttachment{
  height: 100px;
  width: 100px;
}
.content{
  padding: 0 18px
}
.footer{
  text-align: center;
  margin-top: 12px;
  .submitBtn{
    width: 118px;
    height: 40px;
    border-radius: 4px;
  }
}
.Highlight{
  background-color: aquamarine;
}
.itxst {
  width: 99%;
  max-height: 75vh;
  overflow: auto;
  display: flex;
  justify-content: space-between;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #eee;
  padding: 6px 10px;
  text-align: left;
  .item-btn{
    width: 12%;
    display: flex;
    justify-content: space-between;
  }

}
.attachment-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #eee;
  padding: 6px 10px;
  text-align: left;
  .attachment-item-btn{
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
.add-btn{
    text-align: center;
    margin-top: 24px;
  }
  .dvTop{
    display: flex;
    align-items: center;
  }

.item:hover {
  cursor: move;
}
.item + .item {
  margin-top: 10px;
}
.ghost {
  border: solid 1px rgb(19, 41, 239);
}
.chosenClass {
  background-color: #f1f1f1;
}
.ml24{
  margin-left: 24px;
}
</style>
