<template>
  <a-modal v-model:visible="modalVisible" :footer="null" :afterClose="handleClose" :width="620">
    <template #title>
      <div class="modalTitle" :style="{borderColor: store.state.themeColor}">
        {{ title }}
      </div>
    </template>
    <div class="content">
      <a-form ref="formRef" :rules="rules" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" labelAlign="right">
        <a-form-item name="name" label="课程名称">
          <a-input v-model:value="formState.name" />
        </a-form-item>
        <a-form-item label="需要付费" required>
          <a-switch v-model:checked="formState.is_free" checked-children="需要" un-checked-children="不需要" />
        </a-form-item>
        <a-form-item name="poster" label="教材封面">
          <a-upload
            v-model:file-list="fileList"
            name="poster"
            list-type="picture-card"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            @change="handleChange"
          >
            <img v-if="formState.poster" :src="formState.poster" class="coverImg" alt="avatar" />
            <div v-else>
              <loading-outlined v-if="loading"></loading-outlined>
              <plus-outlined v-else></plus-outlined>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
    <div class="footer">
      <a-button type="primary" size="large" class="submitBtn" @click="handleSubmit">
        确定
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { reactive, ref, computed, onBeforeMount, watch } from 'vue'
import { useStore } from 'vuex'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import {
  createCourses,
  editCourse
} from '@/services/course-resource-management'
import { getOssUploadConfig } from '@/services/oss'
import OSS from 'ali-oss'
import moment from 'moment'
import { shareModeMap } from '@/utils/dataMap'
import { moduleCodeMap } from '@/utils/dataMap/teachingMaterial'

export default {
  name: 'Edit',
  components: {
    PlusOutlined,
    LoadingOutlined
  },
  props: {
    type: String,
    visible: Boolean,
    data: Object
  },
  setup (props, { emit }) {
    const store = useStore()
    const modalVisible = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val)
      }
    })
    const title = computed(() => props.data.id ? '编辑课程' : '创建课程')
    const formRef = ref()
    const fileList = ref([])
    const loading = ref(false)
    const ossConfig = {
      domain: null,
      client: null
    }

    const rules = {
      name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      poster: [{ required: true, message: '请上传图片', trigger: 'blur' }]
    }

    const formState = ref({
      is_free: '',
      name: '',
      poster: '',
      sort: 1
    })

    const formOpts = reactive({
      subject: [],
      gradePeriod: [],
      pressVersion: [],
      share_mode: Object.keys(shareModeMap).map(i => {
        return { value: parseInt(i), label: shareModeMap[i].name }
      }),
      show_modules: Object.keys(moduleCodeMap).map(i => {
        return { value: parseInt(i), label: moduleCodeMap[i].name }
      })
    })

    watch([() => props.data, () => props.visible], () => {
      console.log(12333, props.data)
      let isFree = false
      if (props.data.is_free === 0) {
        isFree = true
      }
      formState.value = {
        name: props.data.name ?? '',
        is_free: isFree,
        poster: props.data.poster ?? '',
        sort: props.data.sort ?? 1
      }
    })

    const handleChange = info => {
      if (info.file.status === 'uploading') {
        loading.value = true
        return
      }

      if (info.file.status === 'done') {
        console.log('info', info)
      }

      if (info.file.status === 'error') {
        loading.value = false
        message.error('upload error')
      }
    }

    const beforeUpload = file => {
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg'
      if (!isJPG) {
        message.error('请上传 jpeg、png、jpg 格式图片!')
        return false
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        // 使用ossupload覆盖默认的上传方法
        uploadToOss('/', file).then(data => {
          const url = new URL(data.res.requestUrls)
          file.url = ossConfig.domain + url.pathname
          fileList.value = [...fileList.value, file]
          formState.value.poster = file.url
        })
      }
      return false // 不调用默认的上传方法
    }

    const uploadPath = (path, file) =>
      `${path}/${moment().format('YYYYMMDD')}/${file.name.split('.')[0]}-${
        file.uid
      }.${file.type.split('/')[1]}`

    const uploadToOss = (path, file) => {
      const storeAs = uploadPath(ossConfig.basePath, file)

      return new Promise((resolve, reject) => {
        ossConfig.client
          .multipartUpload(storeAs, file)
          .then(data => {
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }

    const handleSubmit = async () => {
      formRef.value.validate().then(async () => {
        console.log(45454523, formState.value, props.data)
        // 0否1是
        let isFree = 1
        if (formState.value.is_free) {
          isFree = 0
        }
        const params = {
          name: formState.value.name,
          poster: formState.value.poster,
          is_free: isFree,
          // 不需要排序所以都是1
          sort: 1
        }

        if (!props.data.id) {
          await createCourses(params)
          console.log(454512, params)
        } else {
          await editCourse(props.data.id, params)
          // await createTags(props.data.id, {
          //   tags: formState.value.tags
          // })
        }
        emit('ok')
      }).catch(e => {
        console.log(e)
      })
    }

    const getOssConfig = async () => {
      const { upload_config: data } = await getOssUploadConfig()
      ossConfig.client = new OSS({
        accessKeyId: data.access_key_id,
        accessKeySecret: data.access_key_secret,
        region: data.region,
        bucket: data.bucket,
        stsToken: data.sts_token
      })
      ossConfig.domain = data.download_domain
      ossConfig.basePath = data.base_path
    }

    const handleClose = () => {
      formRef.value.clearValidate()
    }

    onBeforeMount(async () => {
      await getOssConfig()
    })

    return {
      labelCol: {
        span: 5
      },
      wrapperCol: {
        span: 18
      },
      formRef,
      rules,
      formOpts,
      formState,
      title,
      store,
      modalVisible,
      fileList,
      loading,
      handleChange,
      beforeUpload,
      handleSubmit,
      handleClose
    }
  }
}
</script>

<style lang="less" scoped>
.modalTitle{
  margin-top: 10px;
  font-size: 20px;
  border-left: 3px solid;
  padding-left: 20px;
}
.content{
  padding: 0 18px
}
.footer{
  text-align: center;
  margin-top: 12px;
  .submitBtn{
    width: 118px;
    height: 40px;
    border-radius: 4px;
  }
}
.coverImg{
  max-height: 200px;
  width: 200px;
}
</style>
