<template>
  <div v-show="!data.firstLoading">
    <div class="tableDiv">
          <div :style="{ color: store.state.themeColor, display: 'flex',justifyContent: 'flex-end' }">
            <a-button
              class="btn"
              type="primary"
              @click="handleOpenEdit"
            >
              创建新课程
            </a-button>
          </div>
      <div>
        <div class="cardTableDiv" v-show="data.styleType === 1">
          <a-list
            :grid="{ gutter: 24, xs: 1, sm: 3, md: 4, lg: 4, xl: 3, xxl: 3 }"
            :data-source="dataSource"
            :loading="data.tableLoading"
            :locale="locale"
          >
            <template #renderItem="{ item }">
              <CoverBlock
                :type="type"
                :data="item"
                :src="item.poster"
                :title="item.name"
                @copy="handleCopy(item)"
                @openEdit="handleOpenEdit(item)"
                @openSetChapter="handleOpenSetChapter(item)"
                @del="handleDelete(item)"
                @updateStatus="handleUpdateStatus(item)"
              />
            </template>
          </a-list>
        </div>
        <div class="pagination">
          <a-pagination
            v-model:current="paginator.current"
            v-model:pageSize="paginator.pageSize"
            :total="paginator.total"
            :show-total="paginator.showTotal"
            @change="handleTableChange"
            show-quick-jumper
          />
        </div>
      </div>
    </div>
     <TeachingMaterialEdit
      :type="type"
      v-model:data="data.current"
      v-model:visible="data.modalVisible"
      @ok="handleOk"
    />
   <SetChapter
      :type="type"
      v-model:data="data.current"
      v-model:visible="data.setChapterVisible"
      @ok="handleOk"
    />
  </div>
  <a-skeleton v-if="data.firstLoading" />
  <div id="111" ref="reloadDiv" ></div>
</template>

<script>
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { defineComponent, ref, reactive, onBeforeMount, computed, onMounted, onActivated, createVNode, watch } from 'vue'
import { useStore } from 'vuex'
import { isFromSchoolPlatform, debounce, isFromBackEnd } from '@/utils/common'
import CoverBlock from '../components/CoverBlock'
import TeachingMaterialEdit from './Edit'
import SetChapter from './SetChapter'
import {
  getSubjectEnum,
  getGradePeriodEnum,
  getPressVersionEnum,
  getOfficialList,
  deleteOfficialTeachingMaterial,
  getPersonalList,
  deletePersonalTeachingMaterial,
  copyBook
} from '@/services/teaching-material'
import {
  getCourses,
  editCourseEnable,
  editCourseDisable,
  deleteCourse
} from '@/services/course-resource-management'
import { getTags, getTeacherTags } from '@/services/common'
import { checkPermission, filterPermiison, PermissionMap } from '@/utils/auth'
import { eventCenter, event } from '@evideo/frontend-utils'
import config from '@/config'
import Empty from '@/components/common/Empty.vue'

export default defineComponent({
  name: 'TeachingMaterialList',
  components: {
    CoverBlock,
    SetChapter,
    TeachingMaterialEdit
  },
  beforeRouteLeave (to, from, next) {
    eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
      app: config.pptist.name
    })
    console.log('preload end')

    setTimeout(() => {
      next()
    }, 0)
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    console.log(123, store.state)
    const type = ref('1') // 教材类型 1官方 2我的
    const paginator = reactive({
      current: 1, // 当前页
      total: 1, // 总页数
      pageSize: 6,
      showTotal: total => `共${total}条`,
      showQuickJumper: true
    })
    const data = reactive({
      current: {}, // 当前单条数据
      styleType: 1, // 表格风格 1卡片 2列表
      modalVisible: false,
      setChapterVisible: false,
      tableLoading: false,
      firstLoading: true
    })
    const tagParams = reactive({
      pageSize: 50,
      page: 1,
      keyword: ''
    })
    const dataSource = ref([])

    // 第一次进入
    const isFirst = ref(false)
    // 检索为空
    const locale = {
      emptyText: createVNode(Empty, { value: isFirst.value })
    }

    watch(isFirst, (newValue) => {
      if (newValue === true) {
        locale.emptyText = createVNode(Empty, { value: isFirst.value })
      }
    })

    const formState = reactive({
      keyword: '',
      subject: 0,
      gradePeriod: 0,
      pressVersion: 0,
      tags: []
    })
    const formOpts = reactive({
      subject: [],
      gradePeriod: [],
      pressVersion: []
    })
    const reloadDiv = ref()

    // tab页动态渲染数组
    const tabList = filterPermiison([
      {
        key: '1',
        title: '官方教材'
      }
      // {
      //   key: '2',
      //   title: '我的教材',
      //   permission: PermissionMap.TEACHING_MATERIAL_PERSONAL_LIST
      // }
    ])
    console.log('tablist =====>   ', tabList)

    const queryParams = computed(() => {
      const params = {}
      if (formState.keyword) {
        params.name = formState.keyword
      }
      if (formState.subject) {
        params.subject_cat_id = formState.subject
      }
      if (formState.gradePeriod) {
        params.grade_period_cat_id = formState.gradePeriod
      }
      if (formState.pressVersion) {
        params.press_version_cat_id = formState.pressVersion
      }
      if (formState.tags) {
        params.tag = formState.tags
      }
      params.per_page = paginator.pageSize
      params.page = paginator.current
      return params
    })
    const getData = debounce(async (params) => {
      console.log(121212)
      data.tableLoading = true
      params = { ...queryParams.value, page: 1, ...params }

      const { items, pagination } = await getCourses(params)
      console.log('454545', items, pagination)
      dataSource.value = items
      paginator.current = pagination.current
      paginator.total = pagination.total
      data.tableLoading = false
    }, 300, {
      leading: true,
      trailing: false
    })
    const getTagsOptions = debounce(async () => {
      const params = { per_page: tagParams.pageSize, current: tagParams.page, keyword: tagParams.keyword }
      const getTagsService = type.value === '1' ? getTags : getTeacherTags
      formOpts.tags = (await getTagsService(params)).items.map(i => {
        i.label = i.tag
        i.value = i.tag
        return i
      })
    })
    const getOptions = () => {
      getSubjectEnum().then((data) => {
        formOpts.subject = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getPressVersionEnum().then((data) => {
        formOpts.pressVersion = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getGradePeriodEnum().then((data) => {
        formOpts.gradePeriod = data.map(i => {
          i.label = i.name
          i.value = i.id
          return i
        })
      })
      getTagsOptions()
    }
    const changeTableStyle = (value) => {
      data.styleType = value
    }

    const handleOpenEdit = item => {
      data.current = item
      data.modalVisible = true
    }
    const handleOpenSetChapter = item => {
      console.log(121212155552, item)
      data.current = item
      data.setChapterVisible = true
    }

    const handleOk = () => {
      console.log(12345)
      data.modalVisible = false
      message.success('操作成功')
      getData({ page: paginator.current })
    }

    const handleCopy = async (record, e) => {
      const key = 'copy'
      await copyBook(record.id)
      message.loading({
        key,
        content: '正在处理'
      })
      message.success({
        key,
        content: '复制成功'
      })
    }

    const handleUpdateStatus = async item => {
      const updateService = item.status === 0 ? editCourseEnable : editCourseDisable
      console.log(1111, updateService)
      await updateService(item.id)
      message.success('操作成功')
      await getData({ page: paginator.current })
    }
    const handleDelete = async item => {
      await deleteCourse(item.id)
      message.success('删除成功')
      await getData({ page: paginator.current })
    }

    const handleTagSearch = v => {
      tagParams.page = 1
      tagParams.keyword = v
      getTagsOptions()
    }
    const handleTableChange = (page, pageSize) => {
      paginator.current = page
      paginator.pageSize = pageSize
      getData({ page: page })
    }
    const handlePopupScroll = e => {
      if (e.target.scrollTop === e.target.clientHeight) {

      }
      console.log(e)
      console.log(e.target.clientHeight)
    }

    onBeforeMount(async () => {
      // await getOptions()
      await getData()
      data.firstLoading = false
      // 获得数据
      isFirst.value = true
    })

    onMounted(async () => {
      console.log(123)
    })

    onActivated(() => {
      getData({ page: paginator.current })
    })

    return {
      data,
      formState,
      formOpts,
      store,
      type,
      dataSource,
      paginator,
      changeTableStyle,
      PermissionMap,
      getData,
      isFromSchoolPlatform,
      reloadDiv,
      tabList,
      handleOpenEdit,
      handleOk,
      handleCopy,
      handleDelete,
      handleTagSearch,
      handleTableChange,
      handlePopupScroll,
      locale,
      handleUpdateStatus,
      handleOpenSetChapter
    }
  }
})
</script>

<style lang="less" scoped>
.btn {
  border-radius: 6px;
}

.tableDiv {
  background-color: white;
  margin: 60px 30px 0 30px;
  padding: 20px 38px;
  .iconExtra {
    flex: 1;
    //display: inline-block;
    font-size: 24px;
    margin: 4px 8px 0 0;
  }
  .cardTableDiv {
    padding-left: 20px;
  }
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
