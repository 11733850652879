import { getRequest, postRequest, deleteRequest } from '@/utils/http'

export async function getCourses(params) {
  return getRequest('/official/courses', params)
}
export async function createCourses(params) {
  return postRequest('/official/courses/create', params)
}
export async function editCourse(id, params) {
  return postRequest(`/official/course/${id}/edit`, params)
}
// 上架
export async function editCourseEnable(id, params) {
  return postRequest(`/official/course/${id}/enable`, params)
}
// 下架
export async function editCourseDisable(id, params) {
  return postRequest(`/official/course/${id}/disable`, params)
}
// 删除
export async function deleteCourse(id) {
  return deleteRequest(`/official/course/${id}/delete`)
}
// 获取课程章节信息
export async function getCourseSections(id) {
  return getRequest(`/official/course/${id}/sections`)
}
// 设置课程章节
export async function setCourseSections(id, params) {
  return postRequest(`/official/course/${id}/set-sections`, params)
}
